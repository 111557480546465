<template>
  <div class="all-rule-list">
    <el-container>
      <div class="tree-con" style="height: 530px">
        <div style="height: 100%" ref="scrollbar">
          <OrgTree
            @showQs="getInfo"
            ref="orgTree"
            @handleAdd="handleAdd()"
            @handleEdit="handleEdit"
            @refreshDataList="getDataList"
          ></OrgTree>
        </div>
      </div>
      <!-- 	<el-main :style="{ height: clientHeight + 'px' }"> -->
      <div class="qsContent">
        <el-scrollbar
          class="custom-scrollbar"
          style="height: 90%"
          ref="scrollbar"
        >
          <div class="phone computer">
            <el-select
              v-model="duoItem"
              placeholder="请选择"
              v-if="submitStatus !== 1 && isDuo"
              @input="setForm"
            >
              <el-option
                v-for="item in duoList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <div class="form-title">{{ tableName }}</div>
            <v-form-render
              v-if="formJson.widgetList.length"
              :form-json="formJson"
              :form-data="formData"
              :option-data="optionData"
              ref="vFormRef"
            ></v-form-render>
            <div style="text-align: center" v-else>暂无数据</div>
          </div>
          <div class="btn" v-if="formJson.widgetList.length">
            <el-button type="primary" @click="submitForm">保存</el-button>
          </div>
        </el-scrollbar>
      </div>
      <!-- </el-main> -->
    </el-container>
    <choose-question
      v-if="qsDialog"
      ref="chooseQs"
      @closeeQs="closeeQs()"
      @chooseQs="getOther()"
      :type="5"
    >
    </choose-question>
    <choose-question-update
      v-if="qsDialog2"
      ref="chooseQs2"
      @closeeQs="closeeQs()"
      :ruleFormDetail="ruleFormDetail"
      @chooseQs="getOther()"
      :type="5"
    ></choose-question-update>
  </div>
</template>

<script>
import OrgTree from "./tree/tree";
import qs from "qs";
import QRCode from "qrcodejs2";
import Clipboard from "clipboard";
import chooseQuestion from "./components/chooseQuestion";
import chooseQuestionUpdate from "./components/chooseQuestionUpdate";
// import {
// 	ref,
// 	reactive
// } from 'vue'

// const vFormRef = ref(null)
export default {
  components: {
    OrgTree,
    chooseQuestion,
    chooseQuestionUpdate,
  },

  data() {
    return {
      isDuo: false,
      duoItem: "",
      relationId: "",
      first: false,
      ruleFormDetail: {},
      activeName: "1",
      id: "",
      tableName: "",
      /* 注意：formJson是指表单设计器导出的json，此处演示的formJson只是一个空白表单json！！ */
      formJson: {
        widgetList: [],
        formConfig: {},
      },
      formData: {},
      optionData: {},
      dialogStatus: false,
      questionName: "", //搜索内容
      isPublish: "", //是否发布
      isUse: "", //是否启用
      formList2: [],
      beforeList: [], //前序活动列表
      formList: [
        {
          executionTime: "",
          activityName: "",
          activityType: "", //活动类型
          activityDescription: "",
          beforeActivityId: "",
          beforeActivityTime: 0, //前序活动距离天数
          activityStart: "", //第_天
          activityRemarks: "", //备注
          executionTime: "", //执行时间
          orderNum: "", //活动排序
          dialog: false, //服药dialog
          qsDialog: false, //qsDialog
          flag: false, //是否有主题内容
          show: true, //右侧展示
          leftShow: false, //左侧是否展示
          medicineList: [],
          missionaryWorks: [],
          indexLibraries: [],
          new: true,
        },
      ],
      circleList: [],
      edit: false, //是否为编辑页面
      allList: [], //所有方案活动
      dataList: [],
      qsDialog: false,
      qsDialog2: false,
      pageIndex: 1,
      pageSize: 10,
      searchContent: "",
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      userId: "",
      unionId: "",
      copyDialog: false,
      url: "",
      contentTypeSec: "",
      contentType: "",
      dataForm: {
        hospitalId: "", //医院id
        teamId: "", //团队id
        tableName: "", //表单名称
        tableBelongs: 1, //表单权限
        orderWeight: "", //权重
        tableType: "",
        tableModel: "",
        authorityType: 1,
      },
      // rules: {
      //   tableName: [
      //     { required: true, message: "请输入表单名称", trigger: "blur" }
      //   ],
      //   authorityType: [
      //     {
      //       required: true,
      //       message: "请选择表单权限",
      //       trigger: "change"
      //     }
      //   ],
      //   tableModel: [
      //     { required: true, message: "请选择问卷分类", trigger: "change" }
      //   ]
      // },
      array: [],
      menuList: [],
      defaultProps: {
        children: "list",
        label: "name",
        expandTrigger: "hover",
        checkStrictly: true,
        emitPath: true,
      },
      contentType: "",
      contentTypeSec: "",
      flag: 1,
      submitStatus: "",
      infoId: "",
      taskId: "",
      duoList: [],
      tableRule: 0,
      selectChoose: 1, //新增1编辑2
      tableId: "",
      newArr: [],
      clientHeight: `${document.documentElement.clientHeight}` - 400,
      submitTaskId: "",
      currentTreeObj: "",
    };
  },

  watch: {
    duoItem: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.selectChoose = 2;
        } else {
          this.selectChoose = 1;
        }
      },
    },
  },

  mounted() {
    if (localStorage.userInfo) {
      let userInfo = JSON.parse(localStorage.userInfo);
      this.userId = userInfo.id;
      this.unionId = userInfo.doctorUnionId;
    }
    this.getDataList();
    //   this.getActivityList()
  },

  methods: {
    // 提交信息接口
    async submitData(formData, optionData) {
      var that = this;
      let patientInfo = {};
      if (localStorage.patientInfo) {
        patientInfo = JSON.parse(localStorage.patientInfo);
      }
      let params = {};
      let arr = [];
      if (formData != undefined) {
        let typeArr = that.$refs.vFormRef.getFieldWidgets();
        if (typeArr.length) {
          let obj;

          for (let key in formData) {
            obj = {
              infoId: that.infoId,
              teamId: JSON.parse(localStorage.currentTeam).id,
              patientId: patientInfo.patience_id,
              taskId: that.submitTaskId,//that.taskId ? that.taskId : that.infoId,
              relationId: that.relationId,
              cellId: key,
              fillContent: formData[key],
              fillType: "",
            };

            typeArr.forEach((ele) => {
              if (ele.name == key) {
                obj.fillType = ele.type;
              }
            });
            arr.push(obj);
          }
        }

        if (
          (that.submitStatus !== 1 && that.tableRule == 2) ||
          that.tableRule == 3
        ) {
          arr.forEach((item) => {
            this.newArr.forEach((v) => {
              for (let key in v) {
                if (item.cellId === key) {
                  item.id = v[key];
                }
              }
            });
          });
        } else if (
          that.submitStatus !== 1 &&
          that.selectChoose == 2 &&
          that.tableRule == 1
        ) {
          arr.forEach((item) => {
            this.newArr.forEach((v) => {
              for (let key in v) {
                if (item.cellId === key) {
                  item.id = v[key];
                }
              }
            });
          });
        }
        if (arr.length) {
          arr.forEach((chr) => {
            // 判断是否为图片
            if (
              chr.fillType == "picture-upload" ||
              chr.fillType == "file-upload"
            ) {
              let newArray = [];
              if (chr.fillContent && chr.fillContent.length) {
                chr.fillContent.forEach((ele) => {
                  let obj = {};
                  if (ele.response && ele.response.result) {
                    obj = {
                      name: ele.name,
                      url: ele.response.result.filePath,
                    };
                  } else {
                    obj = {
                      name: ele.name,
                      url: ele.url,
                    };
                  }

                  newArray.push(obj);
                });
                chr.fillContent = newArray;
              }
            }
          });
          // console.log("调用了编辑接口",arr);
          // return
          params.list = JSON.stringify(arr);
        }
        
        const { data: res } = await that.$http({
          url: that.$http.adornUrl("/vfWriteInfo/add"),
          method: "post",
          data: qs.stringify(params),
        });
        if (res.status) {
          that.updateSubmitStatusById();
		
        }
      }
    },

    async updateSubmitStatusById() {
      var that = this;
      let params = {
        id: this.taskId,
        submitStatus: 2,
      };
      const { data: res } = await that.$http({
        url: that.$http.adornUrl(`/visitInfo/updateSubmitStatusById`),
        method: "post",
        data: qs.stringify(params),
      });
      if (res.status) {
        that.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
          onClose: () => {
            // that.$router.go(0);
            // that.getDataList();
            that.getInfo(this.currentTreeObj);
			 that.$refs["orgTree"].getDeptList();
          },
        });
      }
    },
    async getInfo(d, n) {
      this.tableName = "";
      this.formJson = {
        widgetList: [],
        formConfig: {},
      };
      this.formData = {};
      this.submitStatus = d.submitStatus;
      this.infoId = d.infoId;
      this.relationId = d.relationId;
      this.taskId = d.id;
      this.currentTreeObj = d;
      console.log("=======", d);
      var that = this;
      if (d && d.infoId) {
        const { data } = await this.$http({
          url: that.$http.adornUrl("/vfTableInfo/info"),
          method: "get",
          params: {
            id: d.infoId,
          },
        });
        if (data.status && data.data) {
          this.duoItem = "";
          this.tableName = data.data.tableName;
          this.tableId = data.data.id;
          if (data.data && data.data.tableContent) {
            this.formJson = JSON.parse(data.data.tableContent);
            console.log("123", this.formJson);
            // return;
            // this.$nextTick(() => {
            //   this.$refs.vFormRef.setFormData(this.formJson)
            // })
            this.tableRule = data.data.tableRule;
            if (data.data.tableRule == 1) {
              //多次填写
              this.getDuoWrite(d);
              this.isDuo = true;
            } else {
              this.isDuo = false;
              this.getWriteInfo(d);
              if (this.submitStatus != 1) {
                //一次填写 已填
                // this.$nextTick(() => {
                // 	this.$refs["vFormRef"].disableForm(true);
                // });
              }
            }

            // if (this.$route.query.activeName == 3) {
            // 	this.getWriteInfo();
            // }

            // console.log(this.formJson);
          }
        } else {
          that.tableName = "";
          that.$message({
            message: data.data.msg,
            type: "error",
            duration: 1500,
            onClose: () => {},
          });
        }
      }
    },
    async handleDelete(s, d, n) {
      this.$confirm("确定进行[删除]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: res } = await this.$http({
            url: this.$http.adornUrl(`/visitInfo/delete?uuid=${d.uuid}`),
            method: "post",
            // data: {
            //   uuid: d.uuid,
            // },
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          }
        })
        .catch(() => {});
    },
    async getWriteInfo(d) {
      var that = this;
      let formInfo = {};
      if (localStorage.currentPatientFormInfo) {
        formInfo = JSON.parse(localStorage.currentPatientFormInfo);
      }
      let taskId = "";
      if (d.taskId && d.taskId != "") {
        taskId = d.taskId;
      } else {
        taskId = d.id;
      }
      that.submitTaskId = taskId;
      const { data } = await this.$http({
        url: that.$http.adornUrl("/vfWriteInfo/info"),
        method: "get",
        params: {
          infoId: d.infoId,
          // relationId: formInfo.id,
          taskId: taskId,
          teamId: d.teamsId,
          patientId: JSON.parse(localStorage.patientInfo).patience_id,
        },
      });
      if (data.status) {
        this.newArr = [];
        let list = [],
          newArr = [];
        if (data.data) {
          list = JSON.parse(data.data);
          console.log("list", list);
          if (list.length) {
            list.forEach((ele) => {
              if (ele.cellId.indexOf('gridsubform')>-1) {
                ele.fillContent = JSON.parse(ele.fillContent);
              }
              if (ele.fillType == "checkbox") {
                if (ele.fillContent.indexOf("[") > -1) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                }
                // if (typeof ele.fillContent == 'number') {
                //   ele.fillContent = [ele.fillContent]
                // }
              }
              if (ele.fillType == "switch") {
              }
              if (
                ele.fillType == "number" ||
                ele.fillType == "radio" ||
                ele.fillType == "slider"
              ) {
                console.log(typeof ele.fillContent);
                ele.fillContent = parseInt(ele.fillContent);
                // if (typeof ele.fillContent == "string") {

                // }
              }
              if (
                ele.fillType == "picture-upload" ||
                ele.fillType == "file-upload" ||
                ele.fillType == "pictureupload"
              ) {
                if (typeof ele.fillContent == "string") {
                  if (ele.fillContent.indexOf("[") > -1) {
                    ele.fillContent = JSON.parse(ele.fillContent);
                  } else {
                    ele.fillContent = [
                      {
                        url: ele.fillContent,
                        name: "图片",
                      },
                    ];
                  }
                }
              }
              if (ele.fillType == "select") {
                if (ele.fillContent.indexOf("[") > -1) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                } else {
                  ele.fillContent = parseInt(ele.fillContent);
                }
              }
              let newObj = {
                [ele.cellId]: ele.fillContent,
              };
              const newObj1 = {
                [ele.cellId]: ele.id,
              };
              newArr.push(newObj);
              this.newArr.push(newObj1);
            });
          }
          let formData = {};
          for (let key in [...newArr]) {
            formData = Object.assign(this.formData, [...newArr][key]);
          }
          console.log("formData", formData);
          this.$nextTick(() => {
            this.$refs["vFormRef"].setFormData(formData);
          });
        }
      } else {
        that.$message({
          message: data.msg,
          type: "error",
          duration: 1500,
          onClose: () => {},
        });
      }
    },
    async getDuoWrite(d) {
      this.newArr = [];
      var that = this;
      let formInfo = {};
      if (localStorage.currentPatientFormInfo) {
        formInfo = JSON.parse(localStorage.currentPatientFormInfo);
      }
      const { data } = await this.$http({
        url: that.$http.adornUrl("/vfWriteInfo/dzxInfo"),
        method: "get",
        params: {
          infoId: d.infoId,
          // relationId: formInfo.id,
          taskId: d.id,
          teamId: d.teamsId,
          patientId: JSON.parse(localStorage.patientInfo).patience_id,
        },
      });
      if (data.status) {
        console.log(data, "duo data");
        this.duoList = data.data.writeNumbers;
      } else {
        that.$message({
          message: data.msg,
          type: "error",
          duration: 1500,
          onClose: () => {},
        });
      }
    },
    async setForm() {
      this.newArr = [];
      if (this.duoItem) {
        this.selectChoose = 2;
      } else {
        this.selectChoose = 1;
      }
      console.log(this.selectChoose, "this.selectChoose");
      console.log("set");
      if (this.duoItem) {
        const { data: res } = await this.$http({
          url: this.$http.adornUrl("//dzxInfo"),
          method: "get",
          params: {
            writeNumber: this.duoItem,
            // infoId: d.infoId,
            // taskId: d.id,
            // teamId: d.teamsId,
            patientId: JSON.parse(localStorage.patientInfo).patience_id,
          },
        });
        console.log(res);
        let list = [],
          newArr = [];
        list = JSON.parse(res.data.list);
        console.log("list", list);
        if (list.length) {
          list.forEach((ele) => {
            if (ele.fillType == "checkbox") {
              if (ele.fillContent.indexOf("[") > -1) {
                ele.fillContent = JSON.parse(ele.fillContent);
              }
              // if (typeof ele.fillContent == 'number') {
              //   ele.fillContent = [ele.fillContent]
              // }
            }
            if (
              ele.fillType == "number" ||
              ele.fillType == "radio" ||
              ele.fillType == "slider"
            ) {
              console.log(typeof ele.fillContent);
              ele.fillContent = parseInt(ele.fillContent);
              // if (typeof ele.fillContent == "string") {

              // }
            }
            if (
              ele.fillType == "picture-upload" ||
              ele.fillType == "file-upload" ||
              ele.fillType == "pictureupload"
            ) {
              if (typeof ele.fillContent == "string") {
                if (ele.fillContent.indexOf("[") > -1) {
                  ele.fillContent = JSON.parse(ele.fillContent);
                } else {
                  ele.fillContent = [
                    {
                      url: ele.fillContent,
                      name: "图片",
                    },
                  ];
                }
              }
            }
            if (ele.fillType == "select") {
              if (ele.fillContent.indexOf("[") > -1) {
                ele.fillContent = JSON.parse(ele.fillContent);
              } else {
                ele.fillContent = parseInt(ele.fillContent);
              }
            }
            let newObj = {
              [ele.cellId]: ele.fillContent,
            };
            const newObj1 = {
              [ele.cellId]: ele.id,
            };
            newArr.push(newObj);
            this.newArr.push(newObj1);
          });
        }
        console.log("list", list);
        let formData = {};
        for (let key in [...newArr]) {
          formData = Object.assign(this.formData, [...newArr][key]);
        }
        this.$nextTick(() => {
          this.$refs["vFormRef"].setFormData(formData);
          // this.$refs["vFormRef"].disableForm(true);
        });
      } else {
        // this.$refs["vFormRef"].disableForm(false);
      }
    },
    submitForm() {
      this.$refs.vFormRef
        .getFormData()
        .then((formData) => {
          this.submitData(formData);
        })
        .catch((error) => {
          // Form Validation failed
          this.$message.error(error);
        });
    },
    closeeQs() {
      this.qsDialog = false;
      this.qsDialog2 = false;
      this.$refs["orgTree"].getDeptList();
    },
    async getActivityList() {
      const { data: result } = await this.$http({
        url: this.$http.adornUrl("/programActivity/list"),
        method: "get",
        params: {
          programInfoId: this.programInfoId,
          pageSize: 999,
        },
      });
      if (result.status) {
        result.data.forEach((v) => {
          v.leftShow = true;
          v.show = false;
          v.dialog = false;
          v.qsDialog = false;
          // 如果有主题内容--药物信息
          if (v.drugsManageId || v.missionaryWorkId || v.followUpId) {
            v.flag = true;
            if (v.drugsManages) {
              v.medicineList = v.drugsManages;
            }
          } else {
            v.flag = false;
            v.medicineList = [];
          }
          // 如果有主题内容--宣教信息--之前用了其他字段名的列表来装 后面就跟拿到的列表名保持一致好了
          // if(){
          //   v.flag = true
          // }else{
          //   v.flag = false
          // }
        });
        result.data.sort((a, b) => {
          return b.orderNum - a.orderNum;
        });
        let once = result.data.filter((v) => {
          return v.activityMode == 1;
        });
        let circle = result.data.filter((v) => {
          return v.activityMode == 2;
        });
        this.allList = result.data;
        this.circleList = circle;
        this.formList = once;
        this.formList2 = JSON.parse(JSON.stringify(once));
        this.dataListLoading = false;
      }
    },
    getOther(val, index) {
      this.formList[index].flag = true;
      console.log(val, "val gteOther", index);
      if (this.formList[index].activityType === 4) {
        // this.formList[index].missionaryWorks = [{workTypeName:val.workTypeName,workName:val.workName,item:val}]
        this.formList[index].missionaryWorks = [val];
      } else {
        // this.formList[index].indexLibraries = [{libraryTypeName:val.type.name,libraryName:val.item.libraryName,item:val.item}]
        this.formList[index].indexLibraries = [val];
      }
    },
    getDataList() {
      let that = this;
      let teamsId = "";
      let doctorId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        doctorId = JSON.parse(localStorage.userInfo).id;
      }
      let params = {
        teamsId: teamsId,
        doctorId: doctorId,
        patienceId: JSON.parse(localStorage.patientInfo).patience_id,
      };

      that.dataListLoading = true;
      that
        .$http({
          url: that.$http.adornUrl("/visitInfo/list"),
          method: "get",
          params: params,
        })
        .then(({ data }) => {
          if (data.status) {
            that.dataList = data.data;
            that.oldList = data.data;
            that.dataListLoading = false;
            if (that.dataList.length == 0) {
              that.$refs.chooseQs.first = true;
            } else {
              that.$refs.chooseQs.first = false;
            }
          }
        });
    },
    async handleAdd() {
      this.qsDialog = true;
    },
    handleEdit(s, d, n) {
      this.ruleFormDetail = s;
      this.qsDialog2 = true;
      this.$nextTick(() => {
        this.$refs.chooseQs2.init(d.data);
      });
      console.log(s);
      console.log(d);
      console.log(n);
    },
    handleChange(node) {
      console.log(node);
      if (node.length) {
        this.dataForm.tableModel = node[0];
        if (node[1]) {
          this.dataForm.tableType = node[1];
        } else {
          this.dataForm.tableType = "";
        }
        this.array = node;
      }
    },
    // 获取字典
    async getDictList() {
      let _this = this;
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      const { data } = await this.$http({
        url: _this.$http.adornUrl(
          "/dict/getVfTableSysDicts?type=" + "表单类别"
        ),
        method: "get",
        params: {
          teamsId,
          pageSize: 999,
        },
      });

      if (data.status) {
        _this.menuList = data.data;
        this.changeData(_this.menuList);
        if (this.dataForm.tableModel) {
          this.array[0] = parseInt(this.dataForm.tableModel);
        }
        if (this.dataForm.tableType) {
          this.array[1] = this.dataForm.tableType;
        }
      }
    },

    changeData(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].list = arr[i].map.list;
        if (arr[i].map.list != null && arr[i].map.list.length != 0) {
          this.changeData(arr[i].map.list);
        }
        if (arr[i].map.list.length < 1) {
          arr[i].list = undefined;
        }
      }
    },
    resetValue() {
      this.questionName = "";
      this.isPublish = "";
      this.isUse = "";
    },
    // getDataList(d, n) {
    //   let teamsId = "";
    //   let enertyId = "";
    //   if (localStorage.currentTeam) {
    //     teamsId = JSON.parse(localStorage.currentTeam).id;
    //     enertyId = JSON.parse(localStorage.currentTeam).entityId;
    //   }
    //   let userInfo = {};
    //   if (localStorage.userInfo) {
    //     userInfo = JSON.parse(localStorage.userInfo);
    //   }
    //   let type = "";
    //   let typeSec = "";
    //   let tableBelongs = "";
    //   if (d) {
    //     if (d.isEdit == false) {
    //       tableBelongs = 1;
    //     }
    //     if (d.type == "表单类别") {
    //       type = d.value;
    //       typeSec = "";
    //       this.contentType = d.value;
    //       this.contentTypeSec = "";
    //     } else {
    //       type = n.parent.data.value;
    //       typeSec = d.value;
    //       this.contentType = n.parent.data.value;
    //       this.contentTypeSec = d.value;
    //     }
    //   }
    //   this.dataListLoading = true;
    //   this.$http({
    //     url: this.$http.adornUrl("/vfTableInfo/list"),
    //     method: "get",
    //     params: {
    //       hospitalId: userInfo.deptPid,
    //       pageSize: this.pageSize,
    //       pageNo: this.pageIndex,
    //       tableName: this.questionName,
    //       publishStatus: this.isPublish,
    //       isUse: this.isUse,
    //       tableBelongs: 1,
    //       tableType: typeSec,
    //       tableModel: type,
    //       teamId: teamsId
    //     }
    //   }).then(({ data }) => {
    //     if (data.status) {
    //       this.dataList = data.data;
    //       this.totalPage = data.totalCount;
    //     } else {
    //       this.dataList = [];
    //       this.totalPage = 0;
    //     }
    //     this.dataListLoading = false;
    //   });
    // },
    // handelChange(val) {
    //   this.getDataList();
    // },
    // // 每页数
    // sizeChangeHandle(val) {
    //   this.pageSize = val;
    //   this.pageIndex = 1;
    //   this.getDataList();
    // },
    // // 当前页
    // currentChangeHandle(val) {
    //   this.pageIndex = val;
    //   this.getDataList();
    // },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    //禁用
    // disableClick(status) {
    //   var that = this;
    //   let ids = that.dataListSelections.map(item => {
    //     return item.id;
    //   });
    //   let statusName = "";
    //   if (status == 1) {
    //     statusName = "发布成功";
    //   } else if (status == 2) {
    //     statusName = "启用成功";
    //   } else if (status == 3) {
    //     statusName = "禁用成功";
    //   } else if (status == 4) {
    //     statusName = "删除成功";
    //   }
    //   if (status == 4) {
    //     this.$confirm(`确定进行删除操作?`, "提示", {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       type: "warning"
    //     })
    //       .then(() => {
    //         that
    //           .$http({
    //             url: that.$http.adornUrl("/vfTableInfo/operate"),
    //             method: "post",
    //             data: qs.stringify({
    //               ids: ids.join(","),
    //               type: status
    //             })
    //           })
    //           .then(({ data }) => {
    //             if (data.status) {
    //               that.$message({
    //                 message: statusName,
    //                 type: "success",
    //                 duration: 1500,
    //                 onClose: () => {
    //                   that.getDataList();
    //                 }
    //               });
    //             } else {
    //               that.$message({
    //                 message: data.msg,
    //                 type: "error",
    //                 duration: 1500,
    //                 onClose: () => {}
    //               });
    //             }
    //           });
    //       })
    //       .catch(() => {});
    //   } else {
    //     that
    //       .$http({
    //         url: that.$http.adornUrl("/vfTableInfo/operate"),
    //         method: "post",
    //         data: qs.stringify({
    //           ids: ids.join(","),
    //           type: status
    //         })
    //       })
    //       .then(({ data }) => {
    //         if (data.status) {
    //           that.$message({
    //             message: statusName,
    //             type: "success",
    //             duration: 1500,
    //             onClose: () => {
    //               that.getDataList();
    //             }
    //           });
    //         } else {
    //           that.$message({
    //             message: data.msg,
    //             type: "error",
    //             duration: 1500,
    //             onClose: () => {}
    //           });
    //         }
    //       });
    //   }
    // },

    addSubject() {
      this.$router.push({
        path: "/dataCollect/add",
        query: {
          contentType: this.contentType,
          contentTypeSec: this.contentTypeSec,
        },
      });
    },
    copyAdd(row) {
      this.copyDialog = true;
      this.getDictList();
      this.dataForm = {
        hospitalId: row.hospitalId, //医院id
        teamId: row.teamId, //团队id
        tableName: row.tableName, //表单名称
        tableBelongs: 1, //表单权限
        orderWeight: row.orderWeight, //权重
        tableType: row.tableType,
        tableModel: row.tableModel,
        authorityType: row.authorityType,
        tableContent: row.tableContent,
        publishStatus: row.publishStatus,
        isUse: row.isUse,
        tableRule: row.tableRule,
        scoreSetting: row.scoreSetting,
      };
    },
    //提交指标库
    submitSubject() {
      let _this = this;
      if (localStorage.currentTeam) {
        _this.dataForm.teamId = JSON.parse(localStorage.currentTeam).id;
      }
      if (localStorage.userInfo) {
        this.dataForm.hospitalId = JSON.parse(localStorage.userInfo).deptPid;
      }

      if (_this.unionId) {
        _this.dataForm.unionId = _this.unionId;
      }
      let params = JSON.parse(JSON.stringify(_this.dataForm));
      params.orderWeight = 0;
      // console.log(params);
      // return
      _this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (_this.flag == 1) {
            _this.flag = 0;
            _this
              .$http({
                url: _this.$http.adornUrl("/vfTableInfo/add"),
                method: "post",
                data: qs.stringify(params),
              })
              .then((data) => {
                if (data.data.status) {
                  _this.$message({
                    message: data.data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.flag = 1;
                      _this.copyDialog = false;
                      _this.getDataList();
                    },
                  });
                } else {
                  _this.flag = 1;
                  _this.$message({
                    message: data.data.msg,
                    type: "error",
                    duration: 1500,
                    onClose: () => {},
                  });
                }
              });
          }
        }
      });
    },
    handleViewEdit(index, row) {
      this.$router.push({
        path: "/dataCollect/add",
        query: {
          id: row.id,
          type: 2,
        },
      });
    },
    handleViewInfo(row) {
      this.$router.push({
        path: "/dataCollect/info",
        query: {
          id: row.id,
        },
      });
    },
    // 预览
    handlePreview(row) {
      this.$router.push({
        path: "/dataCollect/preview",
        query: {
          id: row.id,
        },
      });
    },
    // 数据
    handleData(row) {
      this.$router.push({
        path: "/dataCollect/info",
        query: {
          id: row.id,
          activeName: 3,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.form-title {
  font-size: 20px;
  color: #0476F5;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 10px 0 40px;
}
.qsContent {
  width: 1210px;
  height: 530px;
  border: 1px solid #f2f2f2;
  position: relative;
}
.btn {
  // position: absolute;
  // bottom: 24px;
  // left: 50%;
  // margin-left: -35px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tip-con {
  display: flex;
  height: 40px;
  align-items: center;
  background: #e6f7ff;
  border: 1px solid #bae7ff;
  color: #666;
  padding-left: 16px;

  &.tip-con2 {
    background: #fff0e6;
    border: 1px solid #ff9f62;
  }

  img {
    width: 16px;
    height: 14px;
    margin-right: 8px;
  }
}

.phone {
  // width: 375px;
  min-height: 100%;
  padding: 20px;
  margin: 24px auto;
  overflow: auto;
  margin: 0 auto;

  .btn {
    display: flex;
    justify-content: center;

    .el-button {
      margin-top: 0px;
      width: 20%;
      margin-bottom: 20px;
    }
  }
}

.theder {
  .el-cascader-panel {
    .el-cascader-menu {
      .el-radio {
        height: 100%;
        width: 150px;
        opacity: 0;
        position: absolute;

        // z-index: 10;
        .el-radio__input {
          .el-radio__inner {
            border: 0px;
          }
        }

        .el-radio__input.is-checked {
          .el-radio__inner {
            background: none;
          }
        }
      }
    }
  }
}

.all-rule-list {
  .el-main {
    padding: 0 20px;
    position: relative;
  }

  .search-top {
    padding-bottom: 20px;
    overflow: hidden;

    .search-btn {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .share-box {
    line-height: 3em;
  }

  .copy-box {
    display: flex;
    justify-content: space-between;

    .qrcode {
      width: 200px;
      height: 200px;
    }
  }

  .el-button--text {
    white-space: normal;
  }
}

.tree-con {
  width: 360px;
  height: 500px;
  position: relative;
  border: 1px solid #eeeeee;
  padding-top: 24px;
  margin-right: 24px;
}

.divider-con {
  width: 19px;
  height: 649px;
  background: #fafafa;
}

.user,
.sex {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}
</style>
